.account-list-container {
  .ag-header-cell.ag-column-first {
    padding-left: 24px;
  }
}

.status-toast {
  button {
    background-color: var(--white-a2);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &:hover {
      background-color: var(--white-a3);
    }
  }
}
