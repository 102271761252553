.monitor-list-container {
  .rt-TabNavRoot {
    padding: 0;

    .rt-BaseTabList {
      box-shadow: none;
    }
  }

  .table-row:hover {
    background-color: var(--gray-2);
    box-shadow: inset 2px 0px 0px 0px var(--violet-10);
    color: var(--violet-11);
  }
}
