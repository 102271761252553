.issue-list {
  .rt-SegmentedControlItem[data-state="on"] .rt-SegmentedControlItemLabel {
    background: #fff;
    border: 1px solid var(--gray-6);
    color: var(--gray-12);
  }

  .rt-SegmentedControlItem .rt-SegmentedControlItemLabel {
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: none;
    color: var(--gray-9);
  }

  .rt-SegmentedControlIndicator {
    &::before {
      border-radius: 8px;
    }
  }

  .issue-card {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    border: 1px solid var(--gray-4);
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.01),
      0px 2px 1px -1px rgba(0, 0, 0, 0.01),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.01);

    cursor: pointer;
    transition: background-color 0.125s ease-in-out;

    .reference-card {
      background-color: #fff;

      a:hover {
        background-color: var(--violet-1);

        .reference-link {
          stroke: var(--violet-9);
        }
      }
    }

    &:hover {
      &:not(
          :has(
            .reference-card:hover,
            .card-footer:hover,
            .message-history:hover,
            .in-app-message-form:hover
          )
        ) {
        background-color: var(--violet-1);
        .sidebar-toggle svg {
          transition: stroke 0.125s ease-in-out;
          stroke: var(--violet-9);
        }
      }
    }

    button {
      &:hover:not([data-disabled="true"]),
      &[aria-expanded="true"] {
        svg {
          stroke: var(--violet-9);
        }
      }
    }
  }

  .send-email-trigger {
    svg {
      stroke: var(--gray-2) !important;
    }
  }

  .in-app-message-form {
    .rt-TextAreaRoot,
    .rt-TextFieldRoot {
      outline: none;
    }

    .rt-TextAreaInput {
      padding: 12px 12px;
    }

    .rt-TextFieldInput {
      padding: 12px 4px;
      font-weight: 500;
    }

    .send-to-trigger {
      background-color: #fff;
      border: 1px solid var(--gray-5);
      border-radius: 6px;
      box-shadow: none;
      padding: 4px 11px;
      transition: all 0.075s ease-in-out;

      &:hover {
        background-color: var(--gray-1);
        border-color: var(--violet-6);
      }
    }
  }
}
