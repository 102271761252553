.sortable-table {
  &.rt-TableRoot {
    border-radius: 0px;
    border-left: none;
    border-right: none;
    margin-top: -1px;
  }

  .rt-TableRootTable {
    border-radius: 0px;
  }

  .rt-TableColumnHeaderCell {
    font-size: 12px;
    font-weight: 500;
    color: var(--gray-10);
    background-color: var(--gray-2);

    height: 24px;
    padding: 7px 12px;

    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 7px;
      bottom: 7px;
      width: 1px;
      background-color: var(--gray-5);
    }

    &:last-child::after,
    &.hide-divider::after {
      display: none;
    }

    &:first-child .rt-Flex {
      margin-left: 12px;
    }
  }

  .column-header {
    user-select: none;
    width: fit-content;

    &.sortable {
      cursor: pointer;

      &:hover {
        color: var(--violet-10);
      }
    }

    &.current {
      color: var(--violet-10);
    }
  }

  .rt-TableBody {
    .rt-TableRow {
      transition: background-color 0.2s;

      .rt-TableCell {
        padding: 16px 12px;
      }
    }
  }
}
