.monitor-detail-container {
  .results-table {
    position: relative;
    height: calc(100vh - 126px);

    .table-row {
      &.highlighted {
        background-color: var(--violet-3);
      }

      &.outdated {
        /* background-color: var(--gray-2); */

        .rt-Text,
        .detected-badge {
          color: var(--gray-10) !important;
        }
      }
    }

    .rt-TableRootTable {
      overflow: unset;
    }

    .rt-ScrollAreaScrollbar {
      top: 32px !important;
    }

    .rt-ScrollAreaViewport {
      overscroll-behavior: none;
    }
  }
}
