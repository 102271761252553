.login-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: auto;
  background-color: #ccc;
  background-size: cover;
  background-blend-mode: darken;
  background-position-y: -96px;
}

.login-logo {
  padding: 6px 9px;
  background: #faff15;
  user-select: none;
  font-size: 15px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.03em;
}

.login-box {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #c6c6c6;
}
