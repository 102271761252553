nav {
  box-sizing: border-box;
  width: 208px;
  min-width: 208px;
  padding: 16px 12px;
}

nav .logo {
  height: 19px;
  cursor: pointer;
  user-select: none;
  margin-top: 2px;
}

nav .user-avatar {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  background: var(--gray-8);
  color: var(--gray-1);
  cursor: default;
  user-select: none;

  &:hover {
    transition:
      box-shadow 0.125s,
      opacity 0.125s;
    box-shadow: 0 0 0 4px var(--white-a3);
    opacity: 0.8;
  }
}

nav .nav-link {
  border-radius: 8px;
  color: var(--gray-a12);
  text-decoration: none;
  transition: background-color 0.125s;
  user-select: none;

  &:hover,
  &.active {
    background-color: var(--gray-a3);
  }

  &:hover:not(.active) {
    background-color: var(--gray-a2);
  }
}
