.account-sidebar {
  .expandable-list-link {
    transition: color 0.1s ease-in-out;
    cursor: pointer;
    color: var(--gray-10);
    width: fit-content;
    user-select: none;

    &:hover {
      color: var(--violet-9);
    }
  }
}
