:root {
  --bg-color: #e9e7ec;
  --font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

html,
body {
  position: fixed;
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--bg-color);
  font-family: var(--font-family);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.markdown-content {
  p {
    margin-bottom: 0;
    margin-top: 0;
  }

  ul {
    padding-left: 16px;
    margin: 0;
  }

  a {
    color: inherit;
  }
}
