.text-editor {
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .ProseMirror {
    min-height: 48px;
    outline: none;
    font-family: inherit !important;
    font-size: inherit !important;
  }

  /* Basic content styles */
  .ProseMirror p {
    margin: 0;
    line-height: 1.5;
  }

  .ProseMirror ul,
  .ProseMirror ol {
    margin: 0;
    padding-left: 1rem;
  }

  .ProseMirror ul li,
  .ProseMirror ol li {
    margin: 0;
  }

  /* Placeholder styling */
  .tiptap p.is-editor-empty:first-child::before {
    color: #000;
    opacity: 0.35;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
}
