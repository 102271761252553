.data-grid-container {
  width: 100%;
}

.ag-header {
  border-bottom: 1px solid var(--gray-5);
}

.ag-header-cell {
  font-weight: 500;
  color: var(--gray-10);
  background-color: var(--gray-1);
}

.ag-header-cell-text {
  font-size: 12px;
}

/* Fix aggressive AG-Grid styling to match Radix UI themes */
.ag-theme-custom {
  --ag-font-family: inherit;
  --ag-font-size: 13px;
  --ag-grid-size: 4px;
  --ag-border-color: var(--gray-4);
  --ag-row-border-color: var(--gray-3);
  --ag-header-foreground-color: var(--gray-12);
  --ag-foreground-color: var(--gray-12);
  --ag-background-color: #fff;
  --ag-header-background-color: var(--gray-1);
  --ag-selected-row-background-color: var(--accent-a2);
  --ag-range-selection-border-color: var(--accent-8);
  --ag-range-selection-background-color: var(--accent-a3);
  --ag-wrapper-border-radius: 0;
  --ag-wrapper-border: none;

  border: none;
  border-radius: 0;
}

/* Improve filter popup styling */
.ag-popup-child {
  border-radius: 8px;
  box-shadow: var(--shadow-4);
}

/* Fix the loading overlay */
.ag-overlay-loading-wrapper {
  background-color: rgba(255, 255, 255, 0.75);
}

.ag-row {
  border-bottom-color: var(--gray-4);
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--gray-a2);
    box-shadow: inset 2px 0px 0px 0px var(--gray-5);
    /* color: var(--violet-10); */
  }
}

.ag-theme-custom .ag-row.row-active {
  background-color: var(--violet-2);
  box-shadow: inset 3px 0px 0px 0px var(--violet-9);

  &:hover {
    background-color: var(--violet-1);
  }
}

.ag-row-selected {
  background-color: var(--blue-a2);
  box-shadow: inset 3px 0px 0px 0px var(--blue-10) !important;
  color: var(--blue-11);

  &:hover {
    background-color: var(--blue-a1);
  }
}

.ag-checkbox-input-wrapper {
  box-shadow: none !important;
}
