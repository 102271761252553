.account-filters {
  .filter-dropdown {
    z-index: 3;

    .trigger {
      border-radius: 8px;
      transition: background-color 0.1s ease-in-out;

      &:hover {
        background-color: var(--gray-a1);
      }
    }

    .selected[role="option"] .rt-Badge {
      background-color: rgba(255, 255, 255, 0.1);
      color: var(--violet-2);
    }
  }
}
