.App {
  display: flex;
  height: 100%;
  background-position: left calc(100% + 56px);
  background-size: calc(2060px / 4.5) calc(2604px / 4.5);
  background-color: rgba(233, 231, 236, 0.5);
  background-blend-mode: overlay;
  background-repeat: no-repeat;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  height: 100vh;
}

main {
  background: #fff;
  overflow-y: auto;
  height: 100%;
  margin: 8px 8px 8px 0;

  border-radius: 12px;

  border: 1px solid #dddde3;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.01),
    0px 2px 1px -1px rgba(0, 0, 0, 0.01),
    0px 1px 4px 0px rgba(2, 2, 52, 0.08),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.01);
}
