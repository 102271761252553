.monitor-creator-container {
  .editor {
    background: var(--gray-a2);
    backdrop-filter: blur(5px);
    border: 1px solid var(--gray-a4);
    border-radius: 16px;
    width: 100%;
    max-width: 820px;
    z-index: 2;
  }

  .select-trigger {
    border: 1px solid var(--gray-a4);
    border-radius: 8px;
    height: 32px;
    cursor: pointer;
    user-select: none;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:not(.disabled):hover {
      background-color: var(--gray-2);
      color: var(--gray-11) !important;
    }
  }
}
